@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import './styles/paletas';
@import './styles/fonts';
@import './../node_modules/lightgallery/css/lightgallery.css';
@import './../node_modules/lightgallery/css/lg-zoom.css';
@import './../node_modules/lightgallery/css/lg-thumbnail.css';
@import url('./modal.scss');

@font-face {
  font-family: 'Outfit';
  src: url('./assets/fonts/Outfit-Light.ttf');
}

@font-face {
  font-family: 'Outfit-semibold';
  src: url('./assets/fonts/Outfit-SemiBold.ttf');
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-image: url(./assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

h1 {
  // font-family: 'Inter', sans-serif;
  font-family: 'Outfit-semibold', sans-serif;

  font-weight: bold;
}

h2 {
  font-family: 'Outfit-semibold', sans-serif;
}

p {
  font-family: 'Outfit', sans-serif;
}

body::-webkit-scrollbar {
  width: 0.2em;
  background-color: transparent;
}

button,
#copyCCI,
#copyAccount {
  cursor: pointer;
  transition: ease-in-out 0.3s !important;
}

button:hover {
  background-color: #988D7F !important;
}

#copyCCI:hover,
#copyAccount:hover {
  color: #6d5da3 !important;
}

#initial {
  transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    opacity 0.5s ease-in-out, max-height 2s ease-in-out;
  overflow: hidden;
  max-height: 1000px;
}

.slideUp {
  transform: translateY(-100%);
  opacity: 0;
  max-height: 0;
}

.home-container {
  overflow-y: hidden;
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-height: 799px) {
    overflow-y: scroll;
  }

  .home-box {
    gap: 24px;
    @media only screen and (max-width: 1023px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) and (min-width: 500px) {
      margin-top: 248px;
      gap: 0;
    }

    @media only screen and (max-width: 500px) {
      margin-top: 138px;
      gap: 0;
    }
  }
}

///////////////////////////////////////////////////////////////////////

.header-container {
  // overflow-y: hidden;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-height: 799px) {
    // overflow-y: scroll;
  }

  .home-box {
    gap: 24px;
    @media only screen and (max-width: 1023px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) and (min-width: 500px) {
      // margin-top: 248px;
      gap: 0;
    }
  }

  .inner-home-box {
    @media only screen and (max-width: 830px) {
      height: 100%;
      margin-top: 100px;
      gap: 150px;
    }

    @media (max-width: 550px) {
      height: 100%;
      margin-top: 50px;
      gap: 60px;
    }
  }

  .top-left-flower {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @media only screen and (max-width: 568px) {
      height: 250px;
    }
  }

  .buttom-right-flower {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @media only screen and (max-width: 568px) {
      height: 180px;
      // right: -40px;
    }
  }
}

.boyfriends-phrase {
  // overflow-y: hidden;
  left: 0;
  top: 0;

  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-height: 799px) {
    // overflow-y: scroll;
  }

  .home-box {
    gap: 24px;
    @media  only screen and (max-width: 1023px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 830px) and (min-width: 500px) {
      // margin-top: 248px;
      gap: 0;
    }
  }

  .inner-home-box {
    @media  only screen and (max-width: 830px) {
      height: 100%;
      margin-top: 100px;
      gap: 150px;
    }

    @media only screen and (max-width: 550px) {
      height: 100%;
      margin-top: 50px;
      gap: 60px;
    }
  }

  .top-left-flower {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @media only screen and (max-width: 568px) {
      height: 300px;
    }
  }

  .buttom-right-flower {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @media  only screen and (max-width: 568px) {
      height: 220px;
      // right: -40px;
    }
  }
}

.itinerario-container {
  
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }

  .line-itinerario {
    @media only screen and (max-width: 1000px) {
      transform: rotate(90deg);
    }
  }

  .itinerario-sec {
    display: flex;
    gap: 10px;
  
    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
  
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
  
    .item-itinerario {
      display: flex;
      width: 300px;
      gap: 10px;
  
      @media only screen and (min-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 28px;
      }
  
      @media only screen and (max-width: 1000px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
  
      .inner-item {
        display: flex;
  
        @media only screen and (min-width: 1024px) {
          flex-direction: column;
          align-items: center;
        }
    
        @media only screen and (max-width: 1000px) {
          flex-direction: column;
          align-items: baseline;
          text-align: start;
        }
      }
    }
  }
}

.container__primary {
  max-width: 1344px;
  margin: 0 auto;

  @media only screen and (max-width: 1407px) and (min-width: 1216px) {
    max-width: 85%;
  }

  @media only screen and (max-width: 1215px) {
    max-width: 90%;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 60%;
  }

  @media only screen and (max-width: 568px) {
    max-width: 85%;
  }
}

.header {
  background-size: contain;
  background-repeat: no-repeat;
}
.profile-pic {
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
}
.pic {
  @media only screen and (max-width: 600px) {
    width: 267px;
    height: 275px;
    object-fit: contain;
  }

  @media only screen and (min-width: 600px) and (max-width: 992px) {
    width: 346px;
    height: 356px;
    object-fit: contain;
  }
}

.two_columns {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;

  @media only screen and (width< 1024px) {
    grid-template-columns: 100%;
  }
}

.profile {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;

  border-radius: 50%;
  width: 240px;
  height: 240px;

  @media only screen and (max-width: 768px) {
    width: 160px;
    height: 160px;

    bottom: -31%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    width: 190px;
    height: 190px;
    bottom: -16%;
  }
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  @media only screen and (max-width: 768px) {
    background-position: center;
    height: 88%;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1350px) {
    gap: 20px;
    position: absolute;
    right: -96%;
    top: 0;
  }

  @media only screen and (min-width: 1350px) {
    gap: 20px;
    position: absolute;
    right: -77%;
    top: 0;
  }

  .header-logo {
    @media  only screen and (min-width: 760px) and (max-width: 1200px) {
      height: 42px;
    }

    @media only screen and (min-width: 1200px) {
      height: 48px;
      width: 600px;
    }
  }
}

.slider-description-srv {
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 5px !important;

    .line {
      transform: rotate(180deg);
      height: 4px !important;
      width: 60px;
      // border-left: 7px solid var(--dilvant-300);
    }
  }
}

#container__seciton {
  column-count: 2;
  gap: 25px;

  @media only screen and (width< 1024px) {
    column-count: 1;
    margin-bottom: 0 !important;
  }
}

.is-hover-btn {
  &:hover {
    filter: brightness(0.9);
  }
}

.mobile-block {
  @media only screen and (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;

  @media only screen and (max-width: 600px) {
    width: 55px;
    height: 55px;
  }
}

.box {
  border-radius: 24px;
  border: 1px solid #988D7F;
  padding: 2rem;
}

.slick-track {
  display: flex !important;
  // flex-direction: column;
  gap: 23px !important;

  a {
    width: 100%;
    height: 100%;

    img {
      height: 100% !important;
      object-fit: cover;
    }
  }
}

#lightgallery {
  @media only screen and (width <=1024px) {
    a {
      display: none;

      &:nth-last-child(-n + 2) {
        display: block;
        height: 131px;
      }
    }
  }
}

#box_content {
  border-radius: 16px;
  @media only screen and (width <=769px) {
    width: auto !important;
  }
}

.ceremonials-content {
  // background-image: url(./assets/flowes-vector.png);
  background-repeat: no-repeat;
  background-position: -6% top;

  @media only screen and (max-width: 1200px) {
    background-image: unset;
  }
}

.aboutPartyContent {
  // background-image: url(./assets/party-bg.png);
  // transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 9%;

  @media only screen and (max-width: 1200px) {
    background-image: unset;
  }

  &-assistance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;

    @media only screen and (max-width: 1366px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-accept-button{
    background-color: #726A5F!important;
    width: 160px;
  }

  &-decline-button{
    width: 160px;  
    color: #726A5F;
    border: 2px solid #726A5F;
  }

  &-decline-button:hover {
    color: #fff!important;
    border: 2px solid #726A5F;
  }
}

.padrinos-sec {
  @media  only screen and (max-width: 1215px) {
    display: flex;
    flex-direction: column;
  }

  .rings {
    @media only screen and (max-width: 538px) {
      width: 186px;
      height: 240px;
    }

    @media only screen and (max-width: 768px) and (min-width: 539px) {
      width: 233px;
      height: 300px;
    }
  }

  .box {
    @media only screen and (max-width: 768px) and (min-width: 538px) {
      padding: 5% 15% !important;
    }
  }

  .second-box {
    @media only screen and (max-width: 768px) {
      margin-top: 32px;
    }
  }

  .main-padrinos {
    
    @media only screen and (min-width: 831px) {
      display: flex;
    }

    @media only screen and (max-width: 830px) {
      display: flex;
      flex-direction: column;
    }
  }
}

.vector-count-down {
  position: absolute;
  top: 37%;
  left: 0;

  width: 100%;
  z-index: -1;

  @media only screen and (max-width: 600px) {
    position: unset;
    width: 0;
    height: 0 !important;
  }

  img {
    display: flex;
    @media  only screen and (max-width: 991px) {
      display: none;
    }
  }
}

.count-down {
  display: flex;
  flex-direction: column;
  max-width: 436px;
  gap: 25px;

  @media  only screen and (max-width: 600px) {
    max-width: 85%;
    width: 100%;
  }
}

.content-itinerary-info {
  width: 300px !important;

  @media only screen and (max-width: 600px) {
    width: 171px !important;
  }
}

.autoplay {
  height: 100%;

  a img {
    object-fit: cover;
  }
}

.music-button {
  position: fixed;
  right: 7%;
  top: 5%;
  z-index: 10;

  background-color: #eedcc4;
  border-radius: 50%;

  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;

  img {
    width: 20px;
    height: 30px;
  }

  hr {
    position: absolute;
  }
  .pause {
    top: -2px;
    left: 10px;
    width: 30px;
    border-bottom: 3px solid #998379;
    transform: rotate(45deg);
    box-shadow: 0px 1px 3px 1px #f7edfd, 0px 1px 2px 0px #f7edfd;
    // transition: all 0.5s;
  }
}

.title-home {
  @media only screen and (min-width: 1024px) {
    margin-top: 1% !important;
  }
}

.purple-flower-half {
  top: 25%;

  @media only screen and (max-width: 600px) {
    width: 74px;
    top: 19%;
  }
}

.flower-bottom-right {
  position: fixed;
  bottom: -11%;
  right: -4%;
  width: 174px;

  @media only screen and (max-width: 765px) {
    width: auto;
    bottom: -134px;
    right: -69px;
  }

  @media only screen and (max-width: 991px) and (min-width: 765px) {
    bottom: -10%;
    right: -8%;
    width: 170px;
  }
}

.flower-bottom-center {
  position: fixed;
  right: 254px;
  bottom: -66px;

  @media only screen and (max-width: 600px) {
    right: 14vw;
    bottom: -81px;
    width: 154px;
  }

  @media only screen and (max-width: 1025px) and (min-width: 600px) {
    right: 38vw;
    bottom: -74px;
    width: 154px;
  }
}

.group-flowers {
  position: absolute;
  left: 0;
  bottom: 0;

  @media only screen and (max-width: 600px) {
    width: 81px;
  }
}

#goToIndex {
  z-index: 2;
  @media  only screen and (max-width: 600px) {
    width: 155px;
  }
}

.side-flowers {
  position: absolute;

  @media only screen and (max-width: 600px) {
    height: 205px;
  }
}

.footer-flowers {
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 1920px) {
    height: 256px;
  }

  @media only screen and (max-width: 568px) {
    height: 120px;
  }
}

/**********   POPUP CSS   *******/
.popup {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 40px;
  z-index: 100;
  overflow-y: scroll;
  max-height: 550px;

  @media only screen and (max-width: 900px){
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    max-height: 550px;
    width: 75%;
  }

  .popup-title {
    color: #9D7A80;
    text-align: left;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 44px;

    @media only screen and (max-width: 900px){
      color: #9D7A80;
      text-align: left;
      font-weight: 700;
      font-size: 1.25rem;
      margin-bottom: 22px;
    }
  }

  .popup-content{
    font-weight: 400;
    display: block;
    font-size: 1.25rem;
    letter-spacing: 1%;
    text-align: justify;
    margin-bottom: 40px;

    @media only screen and (max-width: 900px){
      font-weight: 400;
      display: block;
      font-size: 1.25rem;
      letter-spacing: 1%;
      text-align: justify;
      margin-bottom: 20px;
    }
  }

  .close-btn{
    max-width: 273px;
    width: 50%;
    height: 48px;
    margin: auto;
    background-color: #9D7A80 ;
    font-size: 18.265px;
    font-weight: 600;


    @media only screen and (max-width: 900px){
      max-width: 273px;
      width: 100%;
      height: 48px;
      margin: auto;
      background-color: #9D7A80;
      font-size: 18.265px;
      font-weight: 600;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a39493bd;
  z-index: 99; 
  display: none;
}